import(/* webpackMode: "eager", webpackExports: ["CarouselItem","Carousel","CarouselPrevious","CarouselNext","CarouselContent"] */ "/vercel/path0/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ContentfulImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Experience"] */ "/vercel/path0/components/Experience.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Skills"] */ "/vercel/path0/components/Skills.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/TestimonialCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Video.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/link.js");
