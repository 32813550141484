'use client'
import type { AboutMeFragment } from '@queries/fragments/aboutme.fragment'
import clsx from 'clsx'
import type { FragmentOf } from 'gql.tada'
import { ChevronsDownIcon, Stars } from 'lucide-react'
import { useState } from 'react'
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from './Collapsible'
import RichText from './RichText'

export const Skills: React.FC<{
    intro: FragmentOf<typeof AboutMeFragment>['skillsSummary']
    skills: FragmentOf<typeof AboutMeFragment>['skills']
}> = ({ intro, skills }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Collapsible
                open={isOpen}
                onOpenChange={setIsOpen}
                className={clsx(
                    'group/skills flex w-full animate-nice-transition cursor-pointer flex-col space-y-4 rounded-md border border-gray-200 p-4 transition-all dark:border-cream/40 dark:hover:bg-black',
                    isOpen && 'shadow-md',
                )}
            >
                <CollapsibleTrigger asChild>
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <Stars className="size-6" />
                                <h3 className="text-lg">My Skills</h3>
                            </div>
                            <div className="flex items-center gap-x-2 text-sm">
                                <p>{!isOpen ? 'View' : 'Hide'} all skills</p>
                                <ChevronsDownIcon
                                    className={clsx(
                                        'size-6 animate-nice-transition transition-all',
                                        isOpen
                                            ? 'group-hover/skills:-translate-y-1 rotate-180'
                                            : ' group-hover/skills:translate-y-1',
                                    )}
                                />
                            </div>
                        </div>
                        <RichText
                            content={intro}
                            prose="not-prose"
                            className="font-bold font-heading text-sm md:text-base"
                        />
                    </div>
                </CollapsibleTrigger>

                <CollapsibleContent>
                    <div className="overflow-x-auto border border-gray-200">
                        <RichText
                            content={skills}
                            prose="not-prose"
                            className="text-sm md:text-base"
                        />
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </>
    )
}
